<template>
	<section class="full-block">
		<div class="container">
			<div class="full-block__content" :class="config.class">
				<h1 class="full-block__hdng" v-html="config.title" />
				<div class="full-block__text" v-html="config.text" />
				<div v-if="isGuest" class="full-block__search-panel">
					<SearchPanel id="search-panel-landing" />
				</div>
				<button
					v-else
					type="button"
					class="btn full-block__send-ad"
					@click="sendAds"
				>
					Подать объявление
				</button>
			</div>
		</div>
	</section>
</template>

<script>
import { defineAsyncComponent } from 'vue'

import { CLEAR } from '@/constants/store/place/mutations'
import modals from '@/constants/modals'

const _config = {
	guest: {
		title: `Сервис для гостеприимных людей`,
		text: `Объединяем гостей и хозяев жилья на выгодных условиях`,
		class: 'full-block__content--guest'
	},
	owner: {
		title: `Делитесь жильём, <br />в котором проживаете сами`,
		text: `Начните сдавать комнату и даже спальное место. <br />Средний доход хозяев — 20 000 рублей.`,
		class: `full-block__content--owner`
	}
}

export default {
	components: {
		SearchPanel: defineAsyncComponent(() =>
			import('@/components/views/home/guest/SearchPanel')
		)
	},
  inject: ["metricsService"],
	props: {
		type: {
			type: String,
			default: 'guest' // guest/owner
		}
	},
	data() {
		return {}
	},
	computed: {
		config() {
			return _config[this.type]
		},
		isGuest() {
			return this.type === 'guest'
		}
	},
	methods: {
		sendAds() {
      this.metricsService.sendEvent("start_housing")

			if (this.$store.getters.isAuth) {
				this.$store.commit(CLEAR)

				this.$router.push({ path: '/place/create' })
			} else {
				this.$store.commit('showModal', {
					name: modals.AUTH,
					props: null,
					isShow: true
				})
			}
		}
	}
}
</script>

<style lang="sass">
.full-block
  &__content
    padding: 11.6%
    padding-bottom: 110px
    border-radius: 20px

    &--guest
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), url('~@/assets/images/new-images/main.webp') no-repeat center
      background-size: cover
      width: 100%
      height: 100%
    &--owner
      text-align: center
      width: 100%
      height: 100%
      background: url('~@/assets/img/pages/home/full-block/hero-owner.jpg') no-repeat center
      background-size: cover

      @supports (background-image: url('~@/assets/img/pages/home/full-block/hero-owner.webp'))
        background: url('~@/assets/img/pages/home/full-block/hero-owner.webp') no-repeat center
        background-size: cover

    @media screen and (max-width: 1050px)
      padding: 0 5%
      padding-bottom: 60px

    @media screen and (max-width: 800px)
      padding: 40px

    @media screen and (max-width: 520px)
      padding: 20px

    @media screen and (max-width: 470px)
      padding: 10px 5px

  &__hdng
    font-weight: 600
    font-size: 48px
    line-height: 56px
    color: #FFF
    margin-bottom: 15px

    @media screen and (max-width: 450px)
      font-weight: 600
      font-size: 24px
      line-height: 28px
      text-align: center

  &__text
    margin-bottom: 55px
    font-size: 20px
    line-height: 23px
    color: #FFF

    @media screen and (max-width: 500px)
      font-weight: 400
      font-size: 14px
      line-height: 19px
      text-align: center

  &__send-ad
    height: 58px
    padding: 0 35px
    font-weight: 700
    font-size: 16px
    line-height: 19px
    background-color: #FFFFFF
    border-radius: 40px
    transition: all 0.5s linear
    color: #2A3043
    &:hover
      background-color: #1AC386
</style>
